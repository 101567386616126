
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="https://c.tenor.com/pux2_3Sj1d4AAAAC/yay-awesome.gif"/>
        <h1> Woooooooh! </h1>
      </header>
     
    </div>
  );
}

export default App;
